import React from 'react';
import {
  ComponentPreview,
  DontDo,
  DontDoItem,
  Link,
  PageHero,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from 'docComponents';
import { SearchInput } from 'hudl-uniform-ui-components';
import SectionPlatform from '../partials/_SectionPlatform';
import searchPreviewData from '../../../../data/previews/search-input.yml';
import pageHeroData from '../../../../data/pages/components.yml';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Search Input"
      designCode
      subnav="components">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Forms"
        tierThree="Search Input"
      />

      <PageNavigation>
        <PageNavigationLink>Size</PageNavigationLink>
        <PageNavigationLink>Mobile</PageNavigationLink>
        <PageNavigationLink>Usage</PageNavigationLink>
        <PageNavigationLink>Microcopy</PageNavigationLink>
        <PageNavigationLink>Platform</PageNavigationLink>
      </PageNavigation>

      <Section title="Size">
        <Paragraph>
          Size should be determined by the amount of white space in the
          interface the search input will be used in.
        </Paragraph>
        <ComponentPreview
          name="SearchSize"
          layout="halfWidth"
          previewData={searchPreviewData.sizes}
        >
          <SearchInput
            placeholder="Find an opponent"
          />
        </ComponentPreview>
      </Section>

      <Section title="Mobile">
        <Paragraph>
          Stick to using native components when designing search for mobile and
          display the results in table or list views.
        </Paragraph>
      </Section>

      <Section title="Usage">
        <SectionSubhead>Labels</SectionSubhead>
        <Paragraph>
          Labels are <strong>mandatory</strong> on all fields, but they don’t
          always have to be visible. This is especially true for search, so make
          use of hidden labels as they make a difference in accessibility.
        </Paragraph>

        <SectionSubhead>Layout</SectionSubhead>
        <Paragraph>
          Search should appear only once in an interface and relate to the
          content it’s presented with.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="exclude search from the context of the interface."
            img="searchinput-context-dont"
          />
          <DontDoItem
            type="do"
            text="keep search relevant to the user’s workflow."
            img="searchinput-context-do"
          />
        </DontDo>

        <SectionSubhead>Results</SectionSubhead>
        <Paragraph>
          Results should always relate to the information input by the user and
          never take them out of context. If a basketball team is searching for
          opponents in the area, results shouldn’t return all opponents in every
          sport.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="ignore key words used and return irrelevant information."
            img="searchinput-irrelevant-info-dont"
          />
          <DontDoItem
            type="do"
            text="treat search as a way to discover content."
            img="searchinput-irrelevant-info-do"
          />
        </DontDo>
      </Section>

      <Section title="Microcopy">
        <SectionSubhead>Placeholders</SectionSubhead>
        <Paragraph>
          Placeholders are <strong>required</strong> and should use verbs to
          suggest what the results will return.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="use placeholders in place of hidden labels."
            img="searchinput-placeholder-dont"
          />
          <DontDoItem
            type="do"
            text="provide instruction with an appropriate action."
            img="searchinput-placeholder-do"
          />
        </DontDo>
        <Paragraph>
          They should follow{' '}
          <Link href="/words/content-elements/labels-non-label-text">
            non-label text guidelines
          </Link>
          , but don’t put punctuation at the end.
        </Paragraph>
      </Section>

      <SectionPlatform />
    </PageWithSubNav>
  );
};

export default IndexPage;
